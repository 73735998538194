
import { ListDataSource } from "@/data/List/ListDataSource";
import {Component,Vue} from "vue-property-decorator";
import FunnelStages from "./comonents/funnel-stages.vue";
import draggable from "vuedraggable";
@Component({
  components: {
    draggable,
    FunnelStages
  }
})
export default class KanbanEdit extends Vue {
  $message:any;
  loaded:boolean = false;

  dataSource:ListDataSource = new ListDataSource({
    className:"LeadFunnel",
    config: {
      orderFieldName:"Priority",
      orderFieldDirection:"ASC",
    }
  })

  async add(){
    await this.dataSource.add({
      caption:"Воронка " + (this.dataSource.items.length + 1)
    })
  }

  async remove(item:any,childCount:number){
    if(childCount > 0) {
      this.$message("С начало удалите этапы")
      return;
    }
    await this.dataSource.remove(item.id)
  }

  async mounted(){
    await this.dataSource.get();
  }
}
