
import { Filter } from "@/cms-services/filter";
import { ListDataSource } from "@/data/List/ListDataSource";
import {Component,Prop,Vue} from "vue-property-decorator";
import metaData from "../metaData.json"

import draggable from "vuedraggable";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
@Component({
  components: {
    draggable
  }
})
export default class FunnelStages extends Vue {

    @Prop() funnelDataSource!:ObjectDataSource 

    loaded:boolean = false;
    $message: any;

    dataSource:ListDataSource = new ListDataSource({
        className:"LeadFunnelStage",
        config: {
            orderFieldName:"Priority",
            orderFieldDirection:"ASC",
            filter: JSON.stringify([
                new Filter("funnelId", this.funnelDataSource.id)
            ])
        }
    })

    colors = [
        ["#AAF0D1", "#B0E57C", "#D8BFD8", "#ADD8E6"],
        ["#90EE90", "#FFB6C1", "#FFA07A", "#F5DEB3"],
        ["#E6E6FA", "#F0E68C", "#B0C4DE", "#87CEFA"],
        ["#FF69B4", "#FFDAB9", "#EEE8AA", "#98FB98"],
        ["#AFEEEE", "#DB7093", "#F08080", "#FAFAD2"]
    ];

    async updateColor(item:any, event:any){
        if(event.hex == "#FF0000") return;
        
        await this.dataSource.updateField(item.id,[
            {
                fieldName:"color",
                fieldValue: event.hex
            }
        ])
    }


    async add(addToEnd:boolean, index:number){
        await this.dataSource.add({
            caption:"Этап " + (this.dataSource.items.length + 1),
            leadFunnelId: this.funnelDataSource.id
        },addToEnd,index)
        this.$message("Этап успешно добавлен")
    }

    async remove(id:number){
        await this.dataSource.remove(id);
        this.$message("Этап успешно удалён")
    }

    async mounted(){
        this.funnelDataSource.metadata = {
            properties: metaData.leadFunnelStage
        };
        
        await this.dataSource.get()
        this.loaded = true;
    }
}
